import React, { useState, createContext, useEffect } from "react";
import axios from "axios";

import { decryptData } from "../Utils/Utils";
import Loader from "../Components/Loader";

export const userContext = createContext();

export const UserProvider = (props) => {
  const [userInfo, setuserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    userPermission: {},
    role: "",
    isAuthenticated: false,
  });
  const [loader, setLoader] = useState(true);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(1000);
    setLoader(false);
  }, [userInfo]);

  const fetchUser = () => {
    axios
      .get(`${process.env.REACT_APP_REGISTRATION_SERVICE}/user/`)
      .then((d) => {
        const userData = decryptData(d.data.data);
        setuserInfo(userData);
        localStorage.removeItem("next");
      })
      .catch(function (e) {
        setLoader(false);
        console.log("error is:", e);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <userContext.Provider value={[userInfo, setuserInfo]}>
      {/* eslint-disable-next-line */}
      {loader ? <Loader /> : props.children}
    </userContext.Provider>
  );
};
