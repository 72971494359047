import { lazy } from "react";

import appRoutes from "../../appRoutes";

const ViewOnlyPage = lazy(()=>import("./Pages/ViewOnlyPage"));
const SearchPage = lazy(() => import("./Pages/SearchPage"));
const CreatePage = lazy(() => import("./Pages/CreatePage"));
const WorkslistPage = lazy(() => import("./Pages/Worklist"));
const IntermediaryDetailPage = lazy(() => import("./Pages/IntermediaryDetail"));

const BpManagementRoutes = [
  {
    path: appRoutes.bpManagementRoutes.searchPageRoutes,
    exact: true,
    name: "Search Page",
    component: SearchPage,
    private: false,
  },
  {
    path: appRoutes.bpManagementRoutes.createPageRoutes,
    exact: true,
    name: "Create Page",
    component: CreatePage,
    private: false,
  },
  {
    path: appRoutes.bpManagementRoutes.approvePageRoutes,
    exact: true,
    name: "Worklist Page",
    component: WorkslistPage,
    private: false,
  },
  {
    path: appRoutes.bpManagementRoutes.viewPageRoutes,
    exact: true,
    name: "Intermediary Detail Page",
    component: IntermediaryDetailPage,
    private: false,
  },
  {
    path: appRoutes.bpManagementRoutes.viewOnlyPageRoutes,
    exact: true,
    name: "Intermediary Detail View Only Page",
    component: ViewOnlyPage,
    private: false,
  },
];

export default BpManagementRoutes;
