import { lazy } from "react";

import appRoutes from "../../appRoutes";

const JournalPage = lazy(() => import("./Pages/Journal.jsx"));

const ClaimStatusPage = lazy(() => import("./Pages/ClaimStatus.jsx"));

const JournalRoutes = [
  {
    path: appRoutes.JournalRoutes.JournalRoutes,
    exact: true,
    name: "Journal",
    component: JournalPage,
    private: false,
  },
  {
    path: appRoutes.JournalRoutes.JournalStatus,
    exact: true,
    name: "Journal Status",
    component: ClaimStatusPage,
    private: false,
  },
];

export default JournalRoutes;
