import { lazy } from "react";

import appRoutes from "../../appRoutes";

const LoginContainer = lazy(() => import("./Pages/LoginPage"));

const loginRoutes = [
  {
    path: appRoutes.loginRoutes.loginRoute,
    exact: true,
    name: "Login",
    component: LoginContainer,
    private: false,
  },
];

export default loginRoutes;
