import { lazy } from "react";

const PageNotFound = lazy(() => import("./Pages/PageNotFound"));

const PageNotFoundRoutes = [
  {
    exact: false,
    name: "PageNotFound",
    component: PageNotFound,
    private: false,
  },
];

export default PageNotFoundRoutes;
