import { lazy } from "react";

import appRoutes from "../../appRoutes";

const LogContainer = lazy(() => import("./Pages/LogContainer"));

const LogRoutes = [
  {
    path: appRoutes.logRoutes.logRoutes,
    exact: true,
    name: "Log",
    component: LogContainer,
    private: false,
  },
];

export default LogRoutes;
