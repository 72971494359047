import { lazy } from "react";

import appRoutes from "../../appRoutes";

const AdminPage = lazy(() => import("./Pages/Admin"));

const AdminRoute = [
  {
    path: appRoutes.adminPageRoutes.paymentUploader,
    exact: true,
    name: "Admin",
    component: AdminPage,
    private: false,
  },
];

export default AdminRoute;
