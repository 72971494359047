import { lazy } from "react";

import appRoutes from "../../appRoutes";

const ResetPasswordPage = lazy(() => import("./Pages/ResetPassword"));
const SetNewPassword = lazy(() => import("./Pages/SetNewPassword"));
const PasswordResetSuccess = lazy(() => import("./Pages/PasswordResetSuccess"));
const PasswordResetEmailSent = lazy(() =>
  import("./Pages/PasswordResetEmailSent")
);

const ResetPasswordRoutes = [
  {
    path: appRoutes.resetPasswordRoutes.resetPasswordRoute,
    exact: true,
    name: "Reset Password",
    component: ResetPasswordPage,
    private: false,
  },
  {
    path: appRoutes.resetPasswordRoutes.setNewPasswordRoute,
    exact: true,
    name: "Set New Password",
    component: SetNewPassword,
    private: false,
  },
  {
    path: appRoutes.resetPasswordRoutes.passwordResetSuccessRoute,
    exact: true,
    name: "Password Reset Success",
    component: PasswordResetSuccess,
    private: false,
  },
  {
    path: appRoutes.resetPasswordRoutes.passwordResetEmailSentRoute,
    exact: true,
    name: "Password Reset Email Sent",
    component: PasswordResetEmailSent,
    private: false,
  },
];

export default ResetPasswordRoutes;
