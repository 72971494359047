import { lazy } from "react";

import appRoutes from "../../appRoutes";

const CorrespondencePage = lazy(() => import("./Pages/CorrespondencePage"));
const MessagePage = lazy(() => import("./Pages/MessagePage"));

const CorrespondenceRoutes = [
  {
    path: appRoutes.correspondenceRoutes.correspondenceRoutes,
    exact: true,
    name: "Correspondence",
    component: CorrespondencePage,
    private: true,
  },
  {
    path: appRoutes.correspondenceRoutes.messageRoutes,
    exact: true,
    name: "MessagePage",
    component: MessagePage,
    private: false,
  },
];

export default CorrespondenceRoutes;
