import { lazy } from "react";

import appRoutes from "../../appRoutes";

const AssignmentPage2 = lazy(() => import("./Pages/AssignmentPage2"));

const AssignmentRoutes2 = [
  {
    path: appRoutes.assignmentRoutes2.assignmentRoutes2,
    exact: true,
    name: "Assignment2",
    component: AssignmentPage2,
    private: false,
  },
];

export default AssignmentRoutes2;
