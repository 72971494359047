import React, { Suspense, memo } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Loader from "../Components/Loader";
import ErrorBoundary from "../Components/ErrorBoundary";
import routes from "./Routes";
import { UserProvider } from "../Context/userContext";

// eslint-disable-next-line
const Routes = memo(() => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <UserProvider>
            <Switch>
              {routes.map((itm) => {
                return (
                  <Route
                    key={itm.name}
                    exact={itm.exact}
                    path={itm.path}
                    name={itm.name}
                    component={itm.component}
                  />
                );
              })}
            </Switch>
          </UserProvider>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
});

export default Routes;
