import { lazy } from "react";

import appRoutes from "../../appRoutes";

const IntimationPage = lazy(() => import("./Pages/IntimationPage"));
const PolicySearchPage = lazy(() => import("./Pages/PolicySearchPage"));
const RegistrationPage = lazy(() => import("./Pages/RegistrationPage"));
const PostRegistrationPage = lazy(() => import("./Pages/PostRegistrationPage"));

const RegistrationRoutes = [
  {
    path: appRoutes.registrationRoutes.intimationRoutes,
    exact: true,
    name: "Intimation Page",
    component: IntimationPage,
    private: false,
  },
  {
    path: appRoutes.registrationRoutes.policySearchRoute,
    exact: true,
    name: "Policy Search Page",
    component: PolicySearchPage,
    private: false,
  },
  {
    path: appRoutes.registrationRoutes.registerClaimRoutes,
    exact: true,
    name: "Registration Page",
    component: RegistrationPage,
    private: false,
  },
  {
    path: appRoutes.registrationRoutes.postRegisterClaimRoutes,
    exact: true,
    name: "Post Registration Page",
    component: PostRegistrationPage,
    private: false,
  },
];

export default RegistrationRoutes;
