import axios from "axios";

import { redirectLogin } from "./Utils/Utils";

const coreService = axios.create({
  baseURL: `${process.env.REACT_APP_REGISTRATION_SERVICE}/`,
});

coreService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

coreService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }

    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    redirectLogin(error.request.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }
    return Promise.reject(error);
  }
);

const registrationService = axios.create({
  baseURL: `${process.env.REACT_APP_REGISTRATION_SERVICE}/api/registration/`,
});

registrationService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

registrationService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }

    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    redirectLogin(error.request.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }

    return Promise.reject(error);
  }
);

const documentService = axios.create({
  baseURL: `${process.env.REACT_APP_REGISTRATION_SERVICE}/api/documents/`,
});

documentService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

documentService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    redirectLogin(error.request.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }

    return Promise.reject(error);
  }
);

const logService = axios.create({
  baseURL: process.env.REACT_APP_LOG_SERVICE,
});

logService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

logService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    redirectLogin(error.request.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }

    return Promise.reject(error);
  }
);

const operationService = axios.create({
  baseURL: process.env.REACT_APP_OPERATION_SERVICE,
});

operationService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;

    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

operationService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    console.log(error);
    redirectLogin(error?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }

    return Promise.reject(error);
  }
);

const assessmentService = axios.create({
  baseURL: `${process.env.REACT_APP_ASSESSMENT_SERVICE}/assessment`,
});
assessmentService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
assessmentService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    console.log(error);
    redirectLogin(error?.request?.responseURL);
    return Promise.reject(error);
  }
);

// const paymentService = axios.create({
//   baseURL: `${process.env.REACT_APP_PAYMENT_SERVICE}/payment/`,
// });
// paymentService.interceptors.request.use(
//   (config) => {
//     const tmpConfig = config;
//     tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
//     return tmpConfig;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// paymentService.interceptors.response.use(
//   (res) => {
//     redirectLogin(res?.request?.responseURL);
//     localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
//     return res;
//   },
//   (error) => {
//     console.log(error);
//     redirectLogin(error?.request?.responseURL);
//     return Promise.reject(error);
//   }
// );

const marutiAssessmentService = axios.create({
  baseURL: `${process.env.REACT_APP_ASSESSMENT_SERVICE}/maruti-assessment`,
});
marutiAssessmentService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
marutiAssessmentService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    console.log(error);
    redirectLogin(error?.request?.responseURL);
    return Promise.reject(error);
  }
);

const nodeService = axios.create({
  baseURL: process.env.REACT_APP_NODE_WRAPPER_SERVICE,
});

const pacmanService = axios.create({
  baseURL: `${process.env.REACT_APP_REGISTRATION_SERVICE}/pacman/`,
});

pacmanService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

pacmanService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   if (res?.request?.responseURL) {
    //     const url = new URL(res.request.responseURL);
    //     if (url.search.includes("next") && url.pathname.includes("login")) {
    //       window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //     }
    //   }
    // }
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    redirectLogin(error.request.responseURL);
    // if (!REACT_APP_UI_ENV) {
    //   const url = new URL(error.request.responseURL);
    //   if (url.search.includes("next")) {
    //     window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
    //   }
    // }

    return Promise.reject(error);
  }
);

const paymentService = axios.create({
  baseURL: `${process.env.REACT_APP_PAYMENT_SERVICE}/payment`,
});

paymentService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["X-CSRFToken"] = localStorage.getItem("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

paymentService.interceptors.response.use(
  (res) => {
    redirectLogin(res?.request?.responseURL);
    localStorage.setItem("csrftoken", res.headers["x-csrftoken"]);
    return res;
  },
  (error) => {
    console.log(error);
    redirectLogin(error?.request?.responseURL);
    return Promise.reject(error);
  }
);

export {
  documentService,
  registrationService,
  nodeService,
  logService,
  coreService,
  operationService,
  pacmanService,
  assessmentService,
  paymentService,
  marutiAssessmentService,
};
