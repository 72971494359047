import { lazy } from "react";

import appRoutes from "../../appRoutes";

const DocumentPage = lazy(() => import("./Pages/DocumentPage.jsx"));
const CustomerUploadDocument = lazy(() =>
  import("./Pages/CustomerUploadDocument.jsx")
);

const documentRoutes = [
  {
    path: appRoutes.documentRoutes.documentRoutes,
    exact: true,
    name: "Document",
    component: DocumentPage,
    private: false,
  },
  {
    path: appRoutes.documentRoutes.uploadDocumentRoutes,
    exact: true,
    name: "Upload-Document",
    component: CustomerUploadDocument,
    private: false,
  },
];

export default documentRoutes;
