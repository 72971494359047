import { lazy } from "react";

import appRoutes from "../../appRoutes";

const Dashboard = lazy(() => import("./Pages/Dashboard"));
const claimSearch = lazy(() => import("./Pages/ClaimSearch"));

const DashboardRoutes = [
  {
    path: appRoutes.dashBoardRoutes.dashBoardRoutes,
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    private: false,
  },
  {
    path: appRoutes.dashBoardRoutes.claimSearchRoutes,
    exact: true,
    name: "Claim-Search",
    component: claimSearch,
    private: false,
  },
];

export default DashboardRoutes;
