import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { styled } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { SnackbarProvider } from "notistack";

import theme from "./theme";
import "./fonts";
import "./Assets/scss/main.scss";

import Routes from "./Routes";
import store from "./Redux/Store/index";
import * as Sentry from "@sentry/react";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background-color: var(--aqua-1);
    color: var(--aqua-9);
  }

  &.SnackbarItem-variantError {
    background-color: var(--coral-1);
  }
`;

// if (process.env.IS_PROD_ENV) {
Sentry.init({
  dsn: "https://fdb56399fe5aa50c4306769cad021a25@sentry.hizuno.com/12",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracin
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        window.location.origin,
        "claims.hizuno.com",
        /^https:\/\/claims\.hizuno\.com.*/,
      ],
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire sessi
});
// }

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store()}>
      <StyledSnackbarProvider maxSnack={3}>
        {/* <SnackbarProvider maxSnack={3}> */}
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: "var(--aqua-2)",
            },
          }}
        />
        <Routes />
        {/* </SnackbarProvider> */}
      </StyledSnackbarProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
