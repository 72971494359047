import { lazy } from "react";

import appRoutes from "../../appRoutes";

const PACMan = lazy(() => import("./Pages/Pacman"));

const PACManRoutes = [
  {
    path: appRoutes.pacmanRoutes.pacmanRoute,
    exact: true,
    name: "PACMan",
    component: PACMan,
    private: false,
  },
];

export default PACManRoutes;
