import { lazy } from "react";

import appRoutes from "../../appRoutes";

const AssignmentPage = lazy(() => import("./Pages/Assignment"));

const AssignmentRoutes = [
  {
    path: appRoutes.assignmentRoutes.assignmentRoutes,
    exact: true,
    name: "Assignment",
    component: AssignmentPage,
    private: false,
  },
];

export default AssignmentRoutes;
