import { lazy } from "react";

import appRoutes from "../../appRoutes";

const AssessmentMainPage = lazy(() => import("./Pages/AssessmentPage"));
const InitialReportPage = lazy(() => import("./Pages/InitialReportPage"));
const PreliminaryReportPage = lazy(() =>
  import("./Pages/PreliminaryReportPage")
);
const FinalReportPage = lazy(() => import("./Pages/FinalReportPage"));
const ExpenseLiabilityPage = lazy(() => import("./Pages/ExpenseLiabilityPage"));
const RepairOrdersPage = lazy(() => import("./Pages/RepairOrdersPage"));
const DeliveryOrdersPage = lazy(() => import("./Pages/DeliveryOrdersPage"));

const AssessmentRoute = [
  {
    path: appRoutes.assessmentRoutes.assessmentRoute,
    exact: true,
    name: "Assessment",
    component: AssessmentMainPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.initialReportRoute,
    exact: true,
    name: "Initial-Report",
    component: InitialReportPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.preliminaryReportRoute,
    exact: true,
    name: "Preliminary-Report",
    component: PreliminaryReportPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.finalReportRoute,
    exact: true,
    name: "Final-Report",
    component: FinalReportPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.repairOrdersRoute,
    exact: true,
    name: "Repair-Order",
    component: RepairOrdersPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.expenseLiabilityRoute,
    exact: true,
    name: "Expense-Liability",
    component: ExpenseLiabilityPage,
    private: false,
  },
  {
    path: appRoutes.assessmentRoutes.deliveryOrdersRoute,
    exact: true,
    name: "Delivery Orders",
    component: DeliveryOrdersPage,
    private: false,
  },
];

export default AssessmentRoute;
