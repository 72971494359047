import { lazy } from "react";

import appRoutes from "../../appRoutes";

const AssessmentMaruti = lazy(() => import("./Pages/AssessmentMaruti"));

const AssessmentMarutiRoutes = [
  {
    path: appRoutes.marutiAssessmentRoutes.marutiAssessmentRoute,
    exact: true,
    name: "Maruti Assessment",
    component: AssessmentMaruti,
    private: false,
  },
];

export default AssessmentMarutiRoutes;
