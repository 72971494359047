import { lazy } from "react";

import appRoutes from "../../appRoutes";

const PaymentPage = lazy(() => import("./Pages/PaymentPage"));

const PaymentRoutes = [
  {
    path: appRoutes.paymentRoutes.paymentRoute,
    exact: true,
    name: "Payment",
    component: PaymentPage,
    private: false,
  },
];

export default PaymentRoutes;
