import createTheme from "@mui/material/styles/createTheme";
import React from "react";
import calender from "./Assets/icons/calendar.svg";
const CustomIcon = () => {
  return <img src={calender} width={"70%"} />;
};
const egiColors = {
  aqua1: "rgb(36, 180, 179)",
  aqua2: "rgb(225, 255, 255)",
  aqua4: "rgb(214, 255, 241)",
  aqua6: "rgb(79, 204, 190)",
  aqua7: "rgb(128, 225, 215)",
  aqua8: "rgb(4, 98, 112)",
  aqua9: "rgb(0, 70, 79)",
  //   CORAL
  coral1: "rgb(255, 118, 113)",
  coral2: "rgb(255, 236, 233)",
  coral4: "rgb(255, 167, 150)",
  coral5: "rgb(250, 137, 135)",
  coral6: "rgb(232, 111, 111)",
  coral7: "rgb(221, 77, 84)",
  //   MUSTARD
  mustard1: "rgb(237, 191, 85)",
  mustard2: "rgb(242, 223, 181)",
  //   GRAY
  gray1: "rgb(233, 248, 248)",
  gray2: "rgb(196, 211, 211)",
  gray3: "rgb(141, 156, 156)",
  gray4: "rgb(88, 103, 103)",
};

const theme = createTheme({
  typography: {
    fontFamily: "Roboto-Regular",
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "1.625rem",
    },
    h3: {
      fontSize: "1.625rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
    deleteiconforprimarybtn: {
      color: "#ffffff",
    },
  },
  shape: {
    borderRadius: 30,
  },
  palette: {
    primary: {
      main: egiColors.aqua9,
    },
    secondary: {
      main: egiColors.coral1,
    },
    turnery: {
      main: egiColors.gray4,
    },
    popUpCloseHeader: {
      // color: "#034EA2",
      color: egiColors.aqua9,
    },
  },
  components: {
    //   TextField
    MuiTextField: {
      defaultProps: {
        // InputProps: { disableUnderline: true },
        color: "primary",
        fullWidth: true,
        autoComplete: "off",
      },
      styleOverrides: {
        root: {
          // "& .Mui-disabled": {
          //   background: "var(--aqua-4) !important",
          //   WebkitTextFillColor: "var(--aqua-9) !important",
          //   pointerEvents: "none",
          // },
          // "&.fieldset": { color: "var(--aqua-9) !important" },
          // "& .MuiOutlinedInput-root:hover": {
          //   "& > fieldset": {
          //     borderColor: "var(--coral-1)",
          //   },
          // },
          "& .MuiInputLabel-asterisk": {
            color: "#000000 !important",
          },
        },
      },
    },

    MuiSelect: {
      // Commented on 16/01/2024 by Ankit Goswami
      // styleOverrides: {
      //   disabled: {
      //     backgroundColor: "var(--aqua-4)",
      //   },
      // },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#000000!important",
        },
      },
    },

    // FormControl
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        color: "turnery",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9)",
          backgroundColor: "var(--aqua-2)",
          "&.Mui-disabled": {
            backgroundColor: "var(--aqua-4)",
          },
        },
      },
    },
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: "var(--aqua-2) !important",
    //     },
    //   },
    // },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "var(--aqua-2) !important",
        },
        option: {
          "&:hover": {
            backgroundColor: "var(--aqua-9) !important",
            color: "#fff !important",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
          backgroundColor: "var(--aqua-2) !important",
          borderRadius: "25px !important",
        },
      },
    },

    // Menu Item
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "var(--aqua-9)",
            color: "#fff",
          },
          // "&.Mui-selected": {
          //   backgroundColor: "var(--aqua-9)",
          //   color: "#fff",
          // },
        },
      },
    },
    // Button
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        fullWidth: true,
        variant: "contained",
        color: "primary",
        size: "large",
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",

          padding: "14px 22px",

          textTransform: "none !important",
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#00282D",
          },
          MuiSvgIcon: {
            color: "#fff !important",
          },
        },
        outlinedPrimary: {
          backgroundColor: "var(--white)",
          "&:hover": {
            backgroundColor: "#00282D",
            color: "#fff",
          },
          MuiSvgIcon: {
            color: "var(--aqua-9)",
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        components: {
          OpenPickerIcon: () => <CustomIcon />,
        },
      },
      styleOverrides: {
        root: {
          color: "var(--aqua-9)",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          ".MuiIconButton-root svg": {
            color: "var(--aqua-9)",
          },
          ".MuiIconButton-root": {
            background: "none",
            ":hover": { background: "none" },
          },
        },
      },
    },
    MuiTimePicker: {
      styleOverrides: {
        root: {
          ".MuiIconButton-root": {
            background: "none",
            ":hover": { background: "none" },
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: egiColors.gray1,
          "&.Mui-checked": {
            transform: "translateX(20px)",
            color: egiColors.gray3,
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: "var(--aqua-9)",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: "1px solid var(--coral-1)",
          color: "#000000",
        },
        icon: {
          color: "var(--coral-1)!important",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9)",
        },
      },
    },
  },
});

export default theme;
