import { lazy } from "react";

import appRoutes from "../../appRoutes";

const LogoutContainer = lazy(() => import("./Pages/LogoutPage"));

const logoutRoute = [
  {
    path: appRoutes.logoutRoute.logoutRoute,
    exact: true,
    name: "Logout",
    component: LogoutContainer,
    private: false,
  },
];

export default logoutRoute;
