import loginRoutes from "../Container/LoginContainer/Routes";
import logoutRoutes from "../Container/LogoutContainer/Routes";
import RegistrationRoutes from "../Container/Registration/Routes";
import DashboardRoutes from "../Container/Dashboard/Routes";
import documentRoutes from "../Container/Document/Routes";
import logRoutes from "../Container/LogContainer/Routes";
import JournalRoutes from "../Container/Journal/Routes";
import AssignmentRoutes2 from "../Container/AssignmentContainer2/Routes";
import CorrespondenceRoutes from "../Container/Correspondence/Routes";
import ReserveRoutes from "../Container/ReserveContainer/Routes";
import AssignmentRoutes from "../Container/AssignmentContainer/Routes";
import BpManagementRoutes from "../Container/BpManagementContainer/Routes";
import AssessmentRoutes from "../Container/AssessmentContainer/Route";
import AdminRoute from "../Container/AdminContainer/Route";
import PageNotFoundRoutes from "../Container/PageNotFound/Routes";
import PACManRoutes from "../Container/PACMan/Routes";
import PaymentRoutes from "../Container/PaymentContainer/Routes";
import AssessmentMarutiRoutes from "../Container/AssessmentMarutiContainer/Routes";
import ResetPasswordRoutes from "../Container/ResetPasswordContainer/Routes";
import { concatArrays } from "../Utils/Utils";

const routes = concatArrays({
  loginRoutes,
  logoutRoutes,
  RegistrationRoutes,
  DashboardRoutes,
  documentRoutes,
  logRoutes,
  JournalRoutes,
  AssignmentRoutes2,
  CorrespondenceRoutes,
  ReserveRoutes,
  AssignmentRoutes,
  AssessmentRoutes,
  BpManagementRoutes,
  PACManRoutes,
  AdminRoute,
  PaymentRoutes,
  AssessmentMarutiRoutes,
  ResetPasswordRoutes,
  PageNotFoundRoutes,
});

export default routes;
