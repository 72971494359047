const appRoutes = {
  loginRoutes: {
    loginRoute: "/login/",
  },
  logoutRoute: {
    logoutRoute: "/logout/",
  },
  dashBoardRoutes: {
    dashBoardRoutes: "/dashboard/",
    claimSearchRoutes: "/",
  },
  registrationRoutes: {
    intimationRoutes: "/registration/:claimid/intimation-details/",
    registerClaimRoutes: "/registration/:callerid/registration/",
    postRegisterClaimRoutes: "/registration/:claimid/success/",
    policySearchRoute: "/registration/search-policy/",
  },

  bpManagementRoutes: {
    searchPageRoutes: "/business-partner/search/",
    createPageRoutes: "/business-partner/create/",
    approvePageRoutes: "/business-partner/worklist/",
    viewPageRoutes: "/business-partner/intermediary/:bproll/:intermediary_id/",
    viewOnlyPageRoutes:
      "/business-partner/intermediary/:bproll/:intermediary_id/view/",
  },

  adminPageRoutes: {
    paymentUploader: "/admin/payment-uploader/",
  },

  correspondenceRoutes: {
    correspondenceRoutes: "/correspondence/:claimid/customer-correspondence/",
    messageRoutes: "/correspondence/:messageid/message/",
  },
  documentRoutes: {
    documentRoutes: "/document/:claimid/document/",
    uploadDocumentRoutes: "/document/:exposecode/upload-document/",
  },
  logRoutes: {
    logRoutes: "/logs/:claimid/",
  },
  JournalRoutes: {
    JournalRoutes: "/journal/:claimid/",
    JournalStatus: "/:exposecode/claim-status/",
  },
  reserveRoutes: {
    reserveRoutes: "/reserve/:claimid/",
  },
  assignmentRoutes: {
    assignmentRoutes: "/assignment/:claimid/",
  },
  assignmentRoutes2: {
    assignmentRoutes2: "/assignment2/:claimid/",
  },
  pacmanRoutes: {
    pacmanRoute: "/pacman/:claimid/",
  },
  assessmentRoutes: {
    assessmentRoute: "/assessment/:claimid/",
    initialReportRoute: "/assessment/:claimid/initial-report/",
    preliminaryReportRoute: "/assessment/:claimid/prelim-report/",
    finalReportRoute: "/assessment/:claimid/final-report/",
    expenseLiabilityRoute: "/assessment/:claimid/expense-liability/",
    repairOrdersRoute: "/assessment/:claimid/repair-orders/",
    deliveryOrdersRoute: "/assessment/:claimid/delivery-orders/",
  },
  marutiAssessmentRoutes: {
    marutiAssessmentRoute: "/maruti-assessment/:claimid/",
  },
  paymentRoutes: {
    paymentRoute: "/payment/:claimid/",
    // paymentDetail: "/payment/:claimid/:modelId/",
  },

  resetPasswordRoutes: {
    resetPasswordRoute: "/accounts/password-reset/",
    setNewPasswordRoute: "/reset/:uid/:token",
    passwordResetSuccessRoute: "/accounts/password-reset/success/",
    passwordResetEmailSentRoute: "/accounts/password-reset/done/",
  },
};

export default appRoutes;
